<template>
	<div class="page">
		<div class="service_banner relative">
			<img class="img" :src="bannerObj.imageUrl || require('../assets/static/zhichan_bg.png')"/>
			<div class="title">
				<div class="fontsize48 colorwhite">{{bannerObj.title || ""}}</div>
				<div class="desc fontsize18 colorwhite">{{bannerObj.subtitle || ""}}</div>
			</div>
		</div>
		<div class="block30"></div>
		<div class="huaxue_zhichan_database_box">
			<!-- 专题数据库 -->
			<div class="zhichan_database w1260">
				<div class="zhichanitem" v-for="(item,index) in databaseList" :key="index" @click="handleUrl(item.redirectUrl,true)">
					<img class="img" :src="item.imageUrl || require('../assets/static/logo.png')"/>
					<div class="text1 fontsize30">{{item.title}}</div>
				</div>
			</div>
			<!-- 知识产权运营 -->
			<div class="block58"></div>
			<div class="zhichan_analysis" v-if="keshihuaList.length>0">
				<div class="huaxue_new_type_box w1239">
					<div class="type_name fontsize38">专利分析可视化</div>
					<div class="type_desc fontsize14">PATENT ANALYSIS VISUALIZATION</div>
				</div>
				<div class="block20"></div>
				<div class="visual_box w1239">
					<div class="vitem" v-for="(item,index) in keshihuaList" :key="index">
						<div class="text fontsize22" @click="handleUrl(item.outUrl,false)">{{item.title}}</div>
						<div class="imgdiv">
							<img class="img" :src="item.iconUrl || require('../assets/static/noimg.png')"/>
						</div>
					</div>
				</div>
			</div>
			<!-- 专利导航预警 -->
			<div class="block58" v-if="dataobj1"></div>
			<div class="zhichan_daohang_yujing_box" v-if="dataobj1">
				<div class="huaxue_new_type_box w1239">
					<div class="type_name fontsize38">{{dataobj1.title}}</div>
					<div class="type_desc fontsize14">PATENT NAVIGATION WARNING</div>
				</div>
				<div class="w1239 contentbox fontsize14">
					<div v-html="dataobj1.contents"></div>
				</div>
			</div>
			
			<!-- 高价值专利培育 -->
			<div class="block58" v-if="dataobj2"></div>
			<div class="zhichan_daohang_yujing_box" v-if="dataobj2">
				<div class="huaxue_new_type_box w1239">
					<div class="type_name fontsize38">{{dataobj2.title}}</div>
					<div class="type_desc fontsize14">HIGH VALUE PATENT CULTIVATION</div>
				</div>
				<div class="w1239 contentbox fontsize14">
					<div v-html="dataobj2.contents"></div>
				</div>
			</div>
			
		</div>
		
		<div class="block30"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			databaseList:[],//题数据库
			keshihuaObj:{},//可视化
			keshihuaList:[],//可视化
			dataobj1:null,
			dataobj2:null,
			bannerObj:{}
		};
	},
	mounted: function() {
		//头图
		this.getBannerFuc(4).then(bannerObj => {
			//console.log(bannerObj.imageUrl)
			if(bannerObj&&bannerObj.imageUrl){
				this.bannerObj = bannerObj
			}
		});
		//头图
		this.pagedatabaseType()
		this.zhuanlifenxikeshihua()
		this.zhuanlidaohangyujing()
		this.gaojiazhizhuanlipeiyu()
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//跳转
		handleUrl(url,needAuth){
			if(url){
				if(needAuth){
					if(!this.isLogin){
						this.$router.push({
							path: "/login"
						});
						return;
					}
				}
				if(url.indexOf("http://")>-1 || url.indexOf("https://")>-1){
					this.$util.windowOpen(url)
				}else{
					this.$router.push({
						name: url
					});
				}
			}else{
				console.log("无跳转路径")
			}
		},
		//获取专题数据库
		pagedatabaseType() {
			var _this = this;
			var params = {
				pid:2,//父级ID
			};
			this.$http.post('frontEnd/catalogue/getSCatalogue', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data;
					if(records.length>0){
						_this.databaseList = records
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//(新 文章)专利分析可视化
		zhuanlifenxikeshihua() {
			var _this = this;
			var params = {};
			this.$http.post('frontEnd/patent/zhuanlifenxikeshihua', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var dataobj = res.data;
					if(dataobj){
						_this.keshihuaObj = dataobj
						var keshihuaList = []
						if(dataobj.firstModule.title){
							keshihuaList.push(dataobj.firstModule)
						}
						if(dataobj.secondModule.title){
							keshihuaList.push(dataobj.secondModule)
						}
						if(dataobj.thirdModule.title){
							keshihuaList.push(dataobj.thirdModule)
						}
						if(dataobj.fourthModule.title){
							keshihuaList.push(dataobj.fourthModule)
						}
						if(dataobj.fifthModule.title){
							keshihuaList.push(dataobj.fifthModule)
						}
						if(dataobj.sixthModule.title){
							keshihuaList.push(dataobj.sixthModule)
						}
						_this.keshihuaList = keshihuaList
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获取专利导航预警
		zhuanlidaohangyujing() {
			var _this = this;
			var params = {};
			this.$http.post('frontEnd/patent/zhuanlidaohangyujing', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var dataobj = res.data;
					if(dataobj){
						_this.dataobj1 = dataobj
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//(文章)高价值专利培育
		gaojiazhizhuanlipeiyu() {
			var _this = this;
			var params = {};
			this.$http.post('frontEnd/patent/gaojiazhizhuanlipeiyu', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var dataobj = res.data;
					if(dataobj){
						_this.dataobj2 = dataobj
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
